<template>
  <div>
    <b-overlay
      :show="ShowRound"
      variant="transparent"
      opacity="0.99"
      blur="5px"
      rounded="sm"
    >
      <template #overlay>
        <div class="text-center">
          <b-icon-controller
            font-scale="3"
            animation="cylon"
          />
          <p id="cancel-label">
            กรุณารอสักครู่...
          </p>
        </div>
      </template>
      <b-row class="match-height">
        <b-col lg="12">

          <b-card>
            <div
              class="row"
            ><div
               class="col-12"
             >
               <div
                 role="alert"
                 aria-live="polite"
                 aria-atomic="true"
                 class="alert alert-primary alert-dismissible fade show mb-2 p-1"
               >
                 แสดงข้อมูลงวดที่ ยังไม่ออกรางวัล
               </div>
             </div>
              <div
                class="col-12"
              >
                <!-- <ol
                  class="breadcrumb"
                >
                  <li
                    class="breadcrumb-item active"
                  >
                    <span
                      aria-current="location"
                    >atarne</span>
                  </li>
                </ol> -->
              </div>
              <div
                class="col-12"
              ><div
                class="table-responsive"
              >
                <table
                  class="table table-bordered table-sm table-hover tb-bd"
                ><thead
                   class="thead-dark"
                 >
                   <tr>
                     <th
                       rowspan="2"
                       colspan="2"
                       class="text-center  align-middle"
                     >
                       ชนิดหวย
                     </th>
                     <th
                       rowspan="2"
                       class="text-center  align-middle bd-r-w"
                     >
                       ยอดทั้งหมด
                     </th>
                     <th
                       colspan="3"
                       class="text-center bd-r-w"
                     >
                       สมาชิก
                     </th>
                     <th
                       colspan="3"
                       class="text-center bd-r-w"
                     >
                       {{ UserData.agent_name }}
                     </th>
                     <th
                       colspan="3"
                       class="text-center bd-r-w"
                     >
                       บริษัท
                     </th>
                   </tr>
                   <tr>
                     <th
                       class="text-center"
                     >
                       ส่งออก
                     </th>
                     <th
                       class="text-center"
                     >
                       คอม
                     </th>
                     <th
                       class="text-center bd-r-w"
                     >
                       รวม
                     </th>
                     <th
                       class="text-center"
                     >
                       ถือหุ้น
                     </th>
                     <th
                       class="text-center"
                     >
                       คอม
                     </th>
                     <th
                       class="text-center bd-r-w"
                     >
                       รวม
                     </th>
                     <th
                       class="text-center"
                     >
                       ถือหุ้น
                     </th>
                     <th
                       class="text-center"
                     >
                       คอม
                     </th>
                     <th
                       class="text-center bd-r-w"
                     >
                       รวม
                     </th>
                     <th
                       class="text-center bd-r-w"
                     >
                       คืนรอบ
                     </th>
                   </tr>
                 </thead>
                  <tbody>
                    <!-- responseData -->
                    <tr
                      v-for="item in responseData"
                      :key="item.name"
                    >
                      <td
                        class="align-middle text-center collapsed"
                        role="button"
                        tabindex="0"
                        aria-expanded="false"
                        aria-controls="collapse-data-0"
                        style="overflow-anchor: none;"
                      >
                        <div
                          class="text-center"
                          style="width: 3rem; justify-content: center; display: inline-flex;"
                        >
                          <img
                            :src="`images/${item.picture}`"
                            alt="Rounded image"
                            height="40"
                            class="mx-auto d-block"
                          >
                        </div>
                      </td>
                      <td
                        role="button"
                        tabindex="0"
                        class="collapsed"
                        aria-expanded="false"
                        aria-controls="collapse-data-0"
                        style="overflow-anchor: none;"
                      >
                        <div

                          class="my-2"
                        >
                          <b>{{ item.name }}</b>

                        </div>
                      </td>
                      <td
                        class="text-right align-middle bd-r-w text-success"
                      >
                        {{ item.total }}
                      </td>
                      <td
                        class="text-right align-middle text-danger"
                      >
                        -{{ item.member_export }}
                      </td>
                      <td
                        class="text-right align-middle text-success"
                      >
                        {{ item.member_com }}
                      </td>
                      <td
                        class="text-right align-middle bd-r-w text-success"
                      >
                        {{ item.member_total }}
                      </td>
                      <td
                        class="text-right align-middle text-success"
                      >
                        {{ item.agent_hold }}
                      </td>
                      <td
                        class="text-right align-middle text-danger"
                      >
                        -{{ item.agent_com }}
                      </td>
                      <td
                        class="text-right align-middle bd-r-w text-success"
                      >
                        {{ item.agent_total }}
                      </td>
                      <td
                        class="text-right align-middle text-success"
                      >
                        {{ item.company_hold }}
                      </td>
                      <td
                        class="text-right align-middle text-danger"
                      >
                        -{{ item.company_com }}
                      </td>
                      <td
                        class="text-right align-middle bd-r-w text-success"
                      >
                        {{ item.company_total }}
                      </td>
                      <td
                        class="align-middle text-center collapsed"
                        role="button"
                        tabindex="0"
                        aria-expanded="false"
                        aria-controls="collapse-data-0"
                        style="overflow-anchor: none;"
                      >
                        <div
                          class="text-center"
                        >
                          <b-button
                            variant="danger"

                            @click="CancleRound(item)"
                          >
                            คืนหวย
                          </b-button>
                        </div>
                      </td>
                    </tr>
                    <!-- <tr>
                      <td
                        colspan="12"
                        class="pd-0"
                      >
                        <div
                          class="collapse"
                          style="display: none;"
                        >
                          <div
                            class="card mb-0"
                          >
                            <div
                              class="card-body"
                            >
                              <div
                                class="table-responsive pd-0 mb-0"
                              >
                                <table
                                  class="table table-bordered table-sm table-hover tb-bd tb-tr-0 pd-0"
                                >
                                  <thead
                                    class="thead-dark"
                                  ><tr>
                                     <th
                                       rowspan="2"
                                       class="text-center  align-middle"
                                     >ชื่อเข้าใช้งาน
                                       <br>
                                       <div
                                         class="custom-control custom-checkbox b-custom-control-sm"
                                       >
                                         <label
                                           class="custom-control-label"
                                         >แสดงชื่อ</label>
                                       </div></th> <th

                                         rowspan="2"
                                         class="text-center  align-middle"
                                       >
                                         ระดับ
                                       </th>
                                     <th
                                       rowspan="2"
                                       class="text-center  align-middle bd-r-w"
                                     >
                                       ยอดทั้งหมด
                                     </th>
                                     <th
                                       colspan="3"
                                       class="text-center bd-r-w"
                                     >
                                       สมาชิก
                                     </th>
                                     <th
                                       colspan="3"
                                       class="text-center bd-r-w"
                                     >
                                       atarne
                                     </th>
                                     <th
                                       colspan="3"
                                       class="text-center"
                                     >
                                       บริษัท
                                     </th>
                                   </tr>
                                    <tr>
                                      <th
                                        class="text-center"
                                      >
                                        ส่งออก
                                      </th>
                                      <th
                                        class="text-center"
                                      >
                                        คอม
                                      </th>
                                      <th
                                        class="text-center bd-r-w"
                                      >
                                        รวม
                                      </th>
                                      <th
                                        class="text-center"
                                      >
                                        ถือหุ้น
                                      </th>
                                      <th
                                        class="text-center"
                                      >
                                        คอม
                                      </th>
                                      <th
                                        class="text-center bd-r-w"
                                      >
                                        รวม
                                      </th>
                                      <th
                                        class="text-center"
                                      >
                                        ถือหุ้น
                                      </th>
                                      <th
                                        class="text-center"
                                      >
                                        คอม
                                      </th>
                                      <th
                                        class="text-center"
                                      >
                                        รวม
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <button
                                          type="button"
                                          class="btn btn-link"
                                        >atarnetbob
                                        </button>
                                      </td>
                                      <td
                                        class="text-center"
                                      >
                                        <span
                                          class="badge badge-success"
                                        >Agent</span>
                                      </td>
                                      <td
                                        class="text-right bd-r-w text-success"
                                      >
                                        60.00
                                      </td>
                                      <td
                                        class="text-right text-danger"
                                      >
                                        -24.00
                                      </td>
                                      <td
                                        class="text-right text-success"
                                      >
                                        1.92
                                      </td>
                                      <td
                                        class="text-right bd-r-w text-success"
                                      >
                                        22.08
                                      </td>
                                      <td
                                        class="text-right text-success"
                                      >
                                        12.00
                                      </td>
                                      <td
                                        class="text-right text-danger"
                                      >
                                        -0.96
                                      </td>
                                      <td
                                        class="text-right bd-r-w text-success"
                                      >
                                        11.04
                                      </td>
                                      <td
                                        class="text-right text-success"
                                      >
                                        12.00
                                      </td>
                                      <td
                                        class="text-right text-danger"
                                      >
                                        -0.96
                                      </td>
                                      <td
                                        class="text-right text-success"
                                      >
                                        11.04
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr> -->
                  </tbody>
                </table>
              </div>
              </div>
              <!-- <div
                class="col-12"
              ><div
                class="h5"
              >
                รวมทั้งหมด
              </div> <div

                class="table-responsive"
              ><table

                class="table table-bordered table-sm table-hover tb-bd"
              ><thead

                 class="thead-dark"
               ><tr><th

                 rowspan="2"
                 class="text-center  align-middle"
               >ชื่อเข้าใช้งาน
                 <br> <div

                   class="custom-control custom-checkbox b-custom-control-sm"
                 >
                   <input
                     id="__BVID__4397"
                     type="checkbox"
                     class="custom-control-input"
                     value="true"
                   ><label
                     class="custom-control-label"
                     for="__BVID__4397"
                   >แสดงชื่อ</label>
                 </div></th> <th

                 rowspan="2"
                 class="text-center  align-middle"
               >
                 ระดับ
               </th> <th

                 rowspan="2"
                 class="text-center  align-middle bd-r-w"
               >
                 ยอดทั้งหมด
               </th> <th

                 colspan="3"
                 class="text-center bd-r-w"
               >
                 สมาชิก
               </th> <th

                 colspan="3"
                 class="text-center bd-r-w"
               /> <th

                 colspan="3"
                 class="text-center"
               >
                 บริษัท
               </th></tr> <tr>
                 <th

                   class="text-center"
                 >
                   ส่งออก
                 </th> <th

                   class="text-center"
                 >
                   คอม
                 </th> <th

                   class="text-center bd-r-w"
                 >
                   รวม
                 </th> <th

                   class="text-center"
                 >
                   ถือหุ้น
                 </th> <th

                   class="text-center"
                 >
                   คอม
                 </th> <th

                   class="text-center bd-r-w"
                 >
                   รวม
                 </th> <th

                   class="text-center"
                 >
                   ถือหุ้น
                 </th> <th

                   class="text-center"
                 >
                   คอม
                 </th> <th

                   class="text-center"
                 >
                   รวม
                 </th>
               </tr>
               </thead>
                <tbody>
                  <tr>
                    <td>
                      <button
                        type="button"
                        class="btn btn-link"
                      >atarnetbob
                      </button>
                    </td>
                    <td
                      class="text-center"
                    >
                      <span
                        class="badge badge-success"
                      >Agent</span>
                    </td>
                    <td
                      class="text-right bd-r-w text-success"
                    >
                      60.00
                    </td> <td

                      class="text-right text-danger"
                    >
                      -24.00
                    </td> <td

                      class="text-right text-success"
                    >
                      1.92
                    </td> <td

                      class="text-right bd-r-w text-success"
                    >
                      22.08
                    </td> <td

                      class="text-right text-success"
                    >
                      12.00
                    </td> <td

                      class="text-right text-danger"
                    >
                      -0.96
                    </td> <td

                      class="text-right bd-r-w text-success"
                    >
                      11.04
                    </td> <td

                      class="text-right text-success"
                    >
                      12.00
                    </td> <td

                      class="text-right text-danger"
                    >
                      -0.96
                    </td> <td

                      class="text-right text-success"
                    >
                      11.04
                    </td></tr> <tr

                    class="tb-bd"
                  >
                    <td

                      colspan="2"
                      class="text-center"
                    >
                      <div

                        class="my-3"
                      >
                        <b>รวม</b>
                      </div>
                    </td> <td

                      class="text-right  font-weight-bold  bd-r-w text-success"
                    >
                      60.00
                    </td> <td

                      class="text-right  font-weight-bold text-danger"
                    >
                      -24.00
                    </td> <td

                      class="text-right  font-weight-bold text-success"
                    >
                      1.92
                    </td> <td

                      class="text-right  font-weight-bold bd-r-w text-success"
                    >
                      22.08
                    </td> <td

                      class="text-right  font-weight-bold text-success"
                    >
                      12.00
                    </td> <td

                      class="text-right  font-weight-bold text-danger"
                    >
                      -0.96
                    </td> <td

                      class="text-right  font-weight-bold bd-r-w text-success"
                    >
                      11.04
                    </td> <td

                      class="text-right  font-weight-bold text-success"
                    >
                      12.00
                    </td>
                    <td
                      class="text-right  font-weight-bold text-danger"
                    >
                      -0.96
                    </td>
                    <td
                      class="text-right  font-weight-bold text-success"
                    >
                      11.04
                    </td>
                  </tr>
                </tbody>
              </table>
              </div>
              </div> -->
            </div>

          </b-card>

        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  // BFormGroup,
  // BButton,
  // BPagination,
  // BTable,
  // BBadge,
  // BInputGroupPrepend,
  // BFormSelect,
  // BFormDatepicker,
  // BFormTimepicker,
  BOverlay,
  BIconController,
  // BFormSelect,
  // BFormCheckbox,
  BButton,
} from 'bootstrap-vue'
// import momenttz from 'moment-timezone'
// import vSelect from 'vue-select'
// import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import {
//   required, integer, between, length, min,
// } from '@validations'

export default {
  components: {
    // vSelect,
    // BInputGroupPrepend,
    // BFormSelect,
    BCard,
    BRow,
    BCol,
    // BFormGroup,
    // BButton,
    // BPagination,
    // BTable,
    // BBadge,
    // BFormDatepicker,
    // BFormTimepicker,
    // ValidationProvider,
    // ValidationObserver,
    BOverlay,
    BIconController,
    // BFormSelect,
    // BFormCheckbox,
    BButton,
  },
  data() {
    return {
      UserData: JSON.parse(localStorage.getItem('userData')),
      responseData: [],
      ShowRound: false,
    }
  },
  computed: {
  },
  mounted() {
    this.GetData()
  },
  methods: {
    async GetData() {
      this.ShowRound = true
      await this.$http
        .post('history/waitting_result')
        .then(async response => {
          this.ShowRound = false
          this.responseData = response.data
          // console.log(response.data)
        })
        .catch(error => console.log(error))
    },
    async CancleRound(item) {
      const resultCheck = await this.$swal({
        title: `คืนหวย ${item.name}`,
        text: `กรุณายืนยันการปิดรอบ ${item.name}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        showClass: {
          popup: 'animate__animated animate__shakeX',
        },
        buttonsStyling: false,
      })
      if (resultCheck.value) {
        const obj = {
          // eslint-disable-next-line no-underscore-dangle
          RoundID: item._id,
          LottoHead: item.LottoHead,
          LottoSubHead: item.LottoSubHead,
        }
        this.ShowRound = true
        await this.$http
          .post('bet/refundbyround', obj)
          .then(this.GetData(), this.ShowRound = false)
      }
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>
<style scoped>
table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  /* border: 1px solid #ddd; */
}
thead th {
  background-color: #746d94 !important;
  color: #fff;
  text-align: center;
}
thead th {
  padding: 1rem !important;
}

tbody {
  background-color: #f1e8e8;
}

caption {
  padding: 10px;
  caption-side: bottom;
}

/* table {
    border-collapse: collapse;
    letter-spacing: 1px;
  } */
/* th,
  td {
    text-align: left;
    padding: 8px;
  }*/

tr:nth-child(even) {
  background-color: #f2f2f2;
}

td,
th {
  border: 1px solid rgb(190, 190, 190);
  padding: 5px 10px;
}

td {
  color: #000;
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
  background-color: $product-details-bg;
}
</style>
